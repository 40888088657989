<template>
  <div>
    <!-- <div>
      <p>{{ this.$route.params.id.work_gid }}</p>
    </div> -->
    <div class="row justify-content-center justify-content-around" :style="`font-family:${font},sans-serif`">
      <div class="card col-12 col-md-12 p-1">
        <div>
          <b-button variant="gradient-secondary" class="mr-1" @click="$router.push({ name: 'selling' })">กลับ</b-button>

          <!-- <b-button variant="gradient-info">Print</b-button>
          <b-button variant="gradient-success" class="ml-1">Excel</b-button>
          <b-button variant="gradient-danger" class="ml-1">PDF</b-button> -->
          <!-- <b-button class="ml-1" v-b-modal.modal-1 variant="outline-primary"> กรรมการประเมินราคากลาง </b-button>
          <b-button class="ml-1" v-b-modal.modal-2 variant="outline-primary"> กรรมการดำเนินการขาย </b-button>
          <b-button class="ml-1" v-b-modal.modal-3 variant="outline-primary"> กรรมการทำลาย </b-button>
          <b-button class="ml-1" v-b-modal.modal-4 variant="outline-primary"> กรรมการโอน </b-button> -->
          <b-button variant="success" class="mr-1 ml-1" @click="post_data">จำหน่าย</b-button>

          <!-- basic modal -->
          <b-modal id="modal-1" title="กรรมการประเมินราคากลาง" ok-only ok-title="ตกลง" no-close-on-backdrop>
            <b-card-text>
              <b-form-group label-cols="3" label-cols-lg="2" label="ชื่อ:">
                <v-select
                  :options="alluser"
                  label="firstname"
                  v-model="person1_name"
                  placeholder="ชื่อ-สกุล"
                  @input="a_position(person1_name)"
                />
              </b-form-group>
              <b-form-group label-cols="3" label-cols-lg="2" label="ตำเเหน่ง:">
                <v-select
                  :options="department_Lists"
                  label="dep_id"
                  v-model="person1_position"
                  placeholder="ตำเเหน่ง"
                  disabled
                />
              </b-form-group>
            </b-card-text>
          </b-modal>

          <b-modal id="modal-2" title="กรรมการดำเนินการขาย" ok-only ok-title="ตกลง" no-close-on-backdrop>
            <b-card-text>
              <b-form-group label-cols="3" label-cols-lg="2" label="ชื่อ:">
                <v-select
                  :options="alluser"
                  label="firstname"
                  v-model="person2_name"
                  placeholder="ชื่อ-สกุล"
                  @input="b_position(person2_name)"
                />
              </b-form-group>
              <b-form-group label-cols="3" label-cols-lg="2" label="ตำเเหน่ง:">
                <v-select
                  :options="department_Lists"
                  label="dep_id"
                  v-model="person2_position"
                  placeholder="ตำเเหน่ง"
                  disabled
                />
              </b-form-group>
            </b-card-text>
          </b-modal>

          <b-modal id="modal-3" title="กรรมการทำลาย" ok-only ok-title="ตกลง" no-close-on-backdrop>
            <b-card-text>
              <b-form-group label-cols="3" label-cols-lg="2" label="ชื่อ:">
                <v-select
                  :options="alluser"
                  label="firstname"
                  v-model="person3_name"
                  placeholder="ชื่อ-สกุล"
                  @input="c_position(person3_name)"
                />
              </b-form-group>
              <b-form-group label-cols="3" label-cols-lg="2" label="ตำเเหน่ง:">
                <v-select
                  :options="department_Lists"
                  label="dep_id"
                  v-model="person3_position"
                  placeholder="ตำเเหน่ง"
                  disabled
                />
              </b-form-group>
            </b-card-text>
          </b-modal>

          <b-modal id="modal-4" title="กรรมการโอน" ok-only ok-title="ตกลง" no-close-on-backdrop>
            <b-card-text>
              <b-form-group label-cols="3" label-cols-lg="2" label="ชื่อ:">
                <v-select
                  :options="alluser"
                  label="firstname"
                  v-model="person4_name"
                  placeholder="ชื่อ-สกุล"
                  @input="d_position(person4_name)"
                />
              </b-form-group>
              <b-form-group label-cols="3" label-cols-lg="2" label="ตำเเหน่ง:">
                <v-select
                  :options="department_Lists"
                  label="dep_id"
                  v-model="person4_position"
                  placeholder="ตำเเหน่ง"
                  disabled
                />
              </b-form-group>
            </b-card-text>
          </b-modal>

          <hr />
          <div class="title head">
            <p class="h2" style="color: #558cef">จำหน่ายทรัพย์สิน</p>
          </div>
          <hr />
          <div class="row justify-content-start">
            <div class="ml-1 h4 text-primary" text="primary">รหัส :</div>
            <div class="ml-1 h4">{{ this.$route.params.id.check_code }}</div>
          </div>
          <!-- search input -->
          <div class="row justify-content-start">
            <div class="ml-1 h4 text-primary" text="primary">ปีงบประมาณ :</div>
            <div class="ml-1 h4">{{ this.$route.params.id.budget_year }}</div>
            <div class="ml-1 h4 text-primary" text="primary">วันที่ :</div>
            <div class="ml-1 h4">{{ this.$route.params.id.action_date }}</div>
          </div>
          <!-- <div class="row justify-content-start">
            <div class="ml-1 h4 text-primary">
              กลุ่มงานผู้ขอเบิก/ผู้ขอเบิก :
            </div>
            <div class="ml-1 h4">กลุ่มผู้อำนวยการ</div>
            <div class="ml-1 h4 text-primary">ผู้เบิก :</div>
            <div class="ml-1 h4">นายหนึ่งสอง สามสี่ห้า</div>
          </div> -->
          <hr />
          <div class="row justify-content-end">
            <b-form-group class="col-12 col-md-6">
              <div class="d-flex align-items-center">
                <label class="mr-1">ค้นหา</label>
                <b-form-input v-model="searchTerm" placeholder="ค้นหา" type="text" class="d-inline-block" />
                <div>
                  <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                    <template v-slot:button-content>
                      <b-button variant="outline-primary"
                        ><feather-icon icon="ShareIcon" class="mr-50" />Export
                        <feather-icon icon="ChevronDownIcon" class="mr-50"
                      /></b-button>
                    </template>

                    <b-dropdown-item @click="exportExcel">
                      <feather-icon icon="FileIcon" class="mr-50" />
                      <span>Excel</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </b-form-group>
          </div>
          <!-- table -->

          <vue-good-table
            :columns="columns"
            :rows="rows"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm,
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
            :line-numbers="true"
          >
            <template slot="table-row" slot-scope="props">
              <!-- Column: Action -->
              <span v-if="props.column.field === 'reject_text'">
                <!-- {{ props.row }} -->
                <span>
                  <v-select
                    placeholder="เลือกวิธีจำหน่าย"
                    v-model="props.row.reject_text"
                    label="text"
                    :options="['ขาย', 'แลกเปลี่ยน', 'โอน', 'แปรสภาพหรือทำลาย']"
                    @input="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
                  />
                </span>
              </span>

              <!-- Column: Action -->
              <span v-else-if="props.column.field === 'sale_text'">
                <span>
                  <div v-if="props.row.reject_text == 'ขาย'">
                    <v-select
                      placeholder="เลือกวิธีขาย"
                      label="text"
                      v-model="props.row.sale_text"
                      :options="['เฉพาะเจาะจง']"
                      @input="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
                    />
                  </div>
                  <div v-else>
                    <v-select
                      disabled
                      placeholder="เลือกวิธีขาย"
                      label="text"
                      v-model="props.row.sale_text"
                      :options="['เฉพาะเจาะจง']"
                      @input="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
                    />
                  </div>
                </span>
              </span>

              <!-- Column: Action -->
              <span v-else-if="props.column.field === 'price'">
                <span>
                  <b-input
                    v-model="props.row.price"
                    placeholder="ราคาประเมิน"
                    @change="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  />
                </span>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Showing 1 to </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['5', '10', '15', '25', '50', '100']"
                    class="mx-1"
                    @input="(value) => props.perPageChanged({ currentPerPage: value })"
                  />
                  <span class="text-nowrap">of {{ props.total }} entries</span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value) => props.pageChanged({ currentPage: value })"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </div>
      </div>
      <!-- <div class="card col-12 col-md-2"></div> -->
    </div>
  </div>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import { BFormInput, BRow, BCol, BFormGroup } from "bootstrap-vue";
import { BTable, BFormRadio, BFormCheckbox, BFormRadioGroup, BCardBody } from "bootstrap-vue";
import vSelect from "vue-select";
import { BButton } from "bootstrap-vue";
import { BFormFile } from "bootstrap-vue";
import { BFormSelect } from "bootstrap-vue";
import { BFormDatepicker } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import axios from "axios";
import API from "@/views/connectDB/condb.js";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import _ from "lodash";
export default {
  components: {
    VueGoodTable,
    BFormDatepicker,
    BFormSelect,
    BFormFile,
    BRow,
    BCol,
    BButton,
    BTable,
    BCardBody,
    vSelect,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BTabs,
    BTab,
  },
  data() {
    return {
      person1_name: "",
      person1_position: "",
      person2_name: "",
      person2_position: "",
      person3_name: "",
      person3_position: "",
      person4_name: "",
      person4_position: "",
      pageLength: 10,
      dir: false,

      options: [
        { value: "ขาย", text: "ขาย" },
        { value: "แลกเปลี่ยน", text: "แลกเปลี่ยน" },
        { value: "โอน", text: "โอน" },
        { value: "แปรสภาพหรือทำลาย", text: "แปรสภาพหรือทำลาย" },
      ],

      optionsa: [{ value: "เฉพาะเจาะจง", text: "เฉพาะเจาะจง" }],
      columns: [
        {
          label: "เลขทะเบียนทรัพย์สิน",
          field: "e_number",
        },
        {
          label: "รายการ",
          field: "e_name",
        },
        {
          label: "ราคา",
          field: "unitprice",
          formatFn: this.formatFn,
        },
        {
          label: "ราคา + vat 7%",
          field: "unitprice_vat",
          formatFn: this.formatFn,
        },
        // {
        //   label: 'ค่าเสื่อมประจำปี',
        //   field: '*',
        // },
        {
          label: "วันที่รับเข้า",
          field: "admitdate",
        },
        {
          label: "ผลการตรวจสอบ",
          field: "performance",
        },
        {
          label: "การจำหน่าย",
          field: "reject_text",
          width: "180px",
        },

        {
          label: "วิธีขาย",
          field: "sale_text",
          width: "150px",
        },
        {
          label: "ราคาประเมิน",
          field: "price",
        },
      ],
      month: [
        "",
        "มกราคม",
        "กุมภาพันธ์ ",
        "มีนาคม ",
        "เมษายน ",
        "พฤษภาคม ",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
      rows: [],
      searchTerm: "",
      alluser: [],
      department_Lists: [],
      font: "",
    };
  },
  created() {
    const font = localStorage.getItem("font");
    console.log(font);
    this.font = font;
    _.isEmpty(this.$route.params.id.check_code) ? this.$router.push({ path: "/selling" }) : true;

    this.get_table();
    this.departmentLists();
    this.get_data_table_user();
  },
  methods: {
    async a_position(value) {
      // console.log(value.firstname);
      const { access_token } = await this.access_token();
      const url = `${API}registerWithdraw?fullname=${value.firstname}`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      // console.log(res.data.message.data);
      this.person1_position = res.data.message.data[0].dep_id;
    },
    async b_position(value) {
      // console.log(value.firstname);
      const { access_token } = await this.access_token();
      const url = `${API}registerWithdraw?fullname=${value.firstname}`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      // console.log(res.data.message.data);
      this.person2_position = res.data.message.data[0].dep_id;
    },
    async c_position(value) {
      // console.log(value.firstname);
      const { access_token } = await this.access_token();
      const url = `${API}registerWithdraw?fullname=${value.firstname}`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      // console.log(res.data.message.data);
      this.person3_position = res.data.message.data[0].dep_id;
    },
    async d_position(value) {
      // console.log(value.firstname);
      const { access_token } = await this.access_token();
      const url = `${API}registerWithdraw?fullname=${value.firstname}`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      // console.log(res.data.message.data);
      this.person4_position = res.data.message.data[0].dep_id;
    },

    async get_data_table_user() {
      this.show = true;
      const { access_token } = await this.access_token();
      const url = `${API}user?_page=1&_limit=100`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      const res_user = res.data.message.data.map((rs) => {
        return {
          ...rs,
          firstname: `${rs.title}${rs.firstname} ${rs.lastname}`,
        };
      });
      this.alluser = res_user;
    },
    async departmentLists() {
      const { access_token } = await this.access_token();
      const url = `${API}departmentLists`;
      const head = {
        headers: {
          authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      this.department_Lists = res.data.message.data;
    },
    formatFn: function (value) {
      return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },
    exportExcel() {
      saveExcel({
        data: this.rows,
        fileName: "ตรวจนับทรัพย์สิน",
        columns: [
          {
            title: "ตรวจนับทรัพย์สิน",
            headerCellOptions: { textAlign: "center" },
            children: [
              {
                title: "เลขทะเบียนทรัพย์สิน",
                field: "e_number",
              },
              {
                title: "รายการ",
                field: "e_name",
              },
              {
                title: "ราคา + vat 7%",
                field: "unitprice_vat",
              },
              {
                title: "วันที่รับเข้า",
                field: "admitdate",
              },
              {
                title: "ผลการตรวจสอบ",
                field: "performance",
              },
            ],
          },
        ],
      });
    },
    updateCountry(person, country) {
      person.country = country;
    },
    async post_data() {
      const { access_token } = await this.access_token();
      const url = `${API}disposeOfList`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const response = await Promise.all(
        this?.rows?.map(
          async (res) =>
            await axios.post(
              url,
              {
                check_code: this.$route.params.id.check_code,
                e_number: res.e_number,
                reject_text: res.reject_text,
                sale_text: res.sale_text,
                price: res.price,
                person1_name: this.person1_name.firstname || " ",
                person1_position: this.person1_position.dep_id || this.person1_position || " ",
                person2_name: this.person2_name.firstname || " ",
                person2_position: this.person2_position.dep_id || this.person2_position || " ",
                person3_name: this.person3_name.firstname || " ",
                person3_position: this.person3_position.dep_id || this.person3_position || " ",
                person4_name: this.person4_name.firstname || " ",
                person4_position: this.person4_position.dep_id || this.person4_position || " ",
              },
              head
            )
        )

        //  person1_name: this.person1_name,
        //         person1_position: this.person1_position,
        //         person2_name: this.person2_name,
        //         person2_position: this.person2_position,
        //         person3_name: this.person3_name,
        //         person3_position: this.person3_position,
        //         person4_name: this.person4_name,
        //         person4_position: this.person4_position,
      );
      this.$router.push({ name: "selling" });
      // console.log(response);
      // const dd = this.rows.map((ss) => {
      //   return {
      //     check_code: this.$route.params.id.check_code,
      //     e_number: ss.e_number,
      //     reject_text: ss.reject_text,
      //     sale_text: ss.sale_text,
      //     price: ss.price,
      //   };
      // });
    },
    changeCell(vaule, column, row) {
      this.rows[row][column] = vaule;
    },
    async get_table() {
      const { access_token } = await this.access_token();
      const url = `${API}equipmentItemStatus?work_gid=${this.$route.params.id.work_gid}&_limit=100`;
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, header);
      // console.log(access_token);
      // console.log(header);
      // console.log(url);
      console.log(res);
      // const gen = console.log(gen);
      this.rows = res.data.message.data.map((el) => {
        return {
          ...el,
          check_code: this.$route.params.id.check_code,
          reject_text: "",
          sale_text: "",
          price: "",
          person1_name: this.person1_name,
          person1_position: this.person1_position,
          person2_name: this.person2_name,
          person2_position: this.person2_position,
          person3_name: this.person3_name,
          person3_position: this.person3_position,
          person4_name: this.person4_name,
          person4_position: this.person4_position,
          admitdate: `${parseInt(el.admitdate.split("-")[2])} ${this.month[parseInt(el.admitdate.split("-")[1])]} ${
            Number(el.admitdate.split("-")[0]) + 543
          }`,
        };
      });
    },
    async access_token() {
      return (
        await axios.post(`${API}permit`, "", {
          headers: {
            Authorization: localStorage.getItem("storedData") && JSON.parse(localStorage.getItem("storedData")),
          },
        })
      ).data.message;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
